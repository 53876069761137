<template>
  <Wrapper>
    <div class="product-display">
      <!-- banner -->
      <DetailBanner title="关于公司业务"></DetailBanner>
      <!-- 产品展示 -->
      <div class="pro-display">
        <div class="w">
          <water-title
            Chinese="产品展示"
            English="PRODUCT DISPLAY"
          ></water-title>
          <div class="bd">
            <ul class="aside">
              <template v-for="(item, index) in proTypes" >
                <li
                  :class="activeIndex === index ? 'active' : ''"
                  @click="onChangeProType(index)"
                >
                  {{ item.label }}
                </li>
              </template>
            </ul>
            <div class="cont">
              <h3>{{ title }}</h3>
              <ul v-if="proList.length > 0">
                <li v-for="item in proList" :key="item.id">
                  <el-carousel trigger="click" height="282px">
                    <el-carousel-item v-for="son in item.imgs" :key="son">
                      <img :src="son" />
                      <div class="paste">
                        <span>{{ item.title }}</span>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </li>
              </ul>
              <div
                v-else
                style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #999;
                "
              >
                <el-empty description="暂无数据"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import DetailBanner from './sections/DetailBanner.vue'
import WaterTitle from '../components/WaterTitle.vue'
import Wrapper from '../components/Wrapper.vue'

export default {
  mixins: [],
  components: { Wrapper, DetailBanner, WaterTitle },
  data() {
    return {
      activeIndex: 0,
      queryForm: {
        offset: 1,
        limit: 4,
        systematicName: '种子种苗',
        statusCd: 0
      },
      proTypes: [
        { label: '种子种苗', value: '种子种苗' },
        { label: '新切药材', value: '新切药材' },
        { label: '中药饮片', value: '中药饮片' },
        { label: '贵细精品', value: '贵细精品' },
        { label: '药食同源', value: '药食同源' },
        { label: '中医名方', value: '中医名方' },
        { label: '消杀产品', value: '消杀产品' }
      ],
      title: '种子种苗',
      proList: []
    }
  },
  created() {
    this.getProList()
  },
  methods: {
    onChangeProType(index) {
      if (this.activeIndex === index) {
        return false
      }
      this.activeIndex = index
      this.title = this.proTypes[index].label
      this.queryForm.systematicName = this.title
      this.getProList()
    },
    async getProList() {
      const res = await this.$axios.getProductDisplayPage(this.queryForm)
      this.proList = res.records.map((item) => {
        return {
          ...item,
          imgs: item.displayUrl.split(',')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pro-display {
  padding: 100px 0;
  .bd {
    display: flex;
  }
  .aside {
    li {
      width: 160px;
      height: 36px;
      font-size: 16px;
      color: #181f2d;
      text-align: right;
      padding-right: 20px;
      line-height: 36px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      &.active {
        background-color: #0e4db6;
        color: #fff;
      }
    }
  }
  .cont {
    width: 100%;
    padding-left: 40px;
    h3 {
      font-size: 24px;
      color: #181f2d;
      font-weight: 700;
      padding-bottom: 20px;
      position: relative;
      margin-bottom: 20px;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 20px;
        height: 4px;
        background-color: #0054e4;
      }
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 30px;
      li {
        position: relative;
        width: 485px;
        overflow: hidden;
        .paste {
          height: 36px;
          line-height: 36px;
          padding-left: 30px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba($color: #0e4db6, $alpha: 0.8);
          font-size: 16px;
          color: #fff;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.product-display {
  .el-carousel__indicator.is-active {
    .el-carousel__button {
      background-color: #57ad23;
    }
  }
  .el-carousel__button {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
  }
}
</style>
